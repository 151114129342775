<template>
    <v-container fluid class="pa-0">
        <v-row class="justify-center">
            <v-col cols="12" sm="10" md="8" lg="6">

                <v-card class="pa-2 pa-md-4">
                    <v-card-text class="text-body-1 text--primary text-hyphenate">
                        <section>
                            <header class="text-h4">
                                Benutzungs&shy;bedingungen allgemeine Bestimmungen
                            </header>
                            <p class="text-justify">
                                Bei Registrierung am GFS-Serviceportal stimmt die teilnehmende Apotheke („Teilnehmer“) der Geltung der nachstehenden
                                Benutzungsbedingungen der vom Portalanbieter GFS Gesellschaft für Statistik im Gesundheitswesen („GFS“) vertretenen
                                Krankenversicherungsträger („Teilnehmende KVT“ bzw. jeder einzelne ein „Teilnehmender KVT“) zu. Die jeweils Teilnehmenden KVT
                                sind auf der Einstiegsseite des GFS-Serviceportals unter Angabe der Leistungen, die vom jeweiligen Teilnehmenden KVT über das
                                GFS-Serviceportal erbracht werden, ersichtlich und bei Neuaufnahme oder Wegfall eines Teilnehmenden KVTs erhält der Teilnehmer
                                eine entsprechende Benachrichtigungs-Mail von GFS.
                            </p>
                            <p class="text-justify">
                                Die nachfolgend im Abschnitt I. aufgeführten Allgemeinen Bestimmungen gelten für das Nutzungsverhältnis zwischen den
                                Teilnehmenden KVTn und dem Teilnehmer insgesamt. Die unter den weiteren Abschnitten aufgeführten Speziellen Bestimmungen
                                regeln einzelne Leistungen der Teilnehmenden KVT gegenüber dem Teilnehmer (Module), soweit (a) der Teilnehmer die Leistungen
                                des jeweiligen Moduls in Anspruch nehmen möchte und (b) die Teilnehmenden KVT die Leistungen des jeweiligen Moduls anbieten.
                            </p>
                            <ol class="pt-2">
                                <li>
                                    Der Teilnehmer hat den bei der erstmaligen Registrierung auf dem GFS-Serviceportal vergebenen Benutzernamen und das
                                    gewählte Passwort gegen eine Kenntnisnahme und Verwendung durch unbefugte Dritte zu schützen. Hat der Teilnehmer Anlass zu
                                    dem Verdacht, eine Kenntnisnahme durch einen unbefugten Dritten habe stattgefunden, ist er verpflichtet, das Passwort
                                    unverzüglich zu ändern. Ebenfalls hat der Teilnehmer dasjenige Endgerät (Smartphone etc.) gegen eine Verwendung bzw.
                                    ein Auslesen durch unbefugte Dritte zu schützen, das im Rahmen der Zwei-Faktor-Authentifizierung genutzt wird, soweit
                                    dies die im Rahmen der Anmeldung am Portal verwendeten, für kurze Zeit gültigen Zugangscodes betrifft.
                                </li>
                                <li>
                                    Der Teilnehmer hat bei der erstmaligen Registrierung eine E-Mail-Adresse anzugeben, unter der er zeitnah, mindestens aber
                                    wochentäglich, Benachrichtigungs-Mails des GFS-Serviceportals zur Kenntnis nehmen kann. Der Teilnehmer nimmt zur Kenntnis,
                                    dass durch den Zugang von Benachrichtigungs-Mails über die Einstellung von Dokumenten im GFS-Serviceportal die zumutbare
                                    Möglichkeit der Kenntnisnahme des betreffenden Dokuments eröffnet wird.
                                </li>
                                <li>
                                    Der Teilnehmer kann jederzeit im GFS-Portal Module anwählen, d. h. die Leistungen des jeweili-gen Moduls ab dem Zeitpunkt
                                    der Anwahl in Anspruch nehmen, soweit die Teilnehmenden KVT die Leistungen des jeweiligen Moduls anbieten.
                                </li>
                                <li>
                                    Der Teilnehmer kann sowohl die Teilnahme am GFS-Portal insgesamt durch Kündigungserklärung gegenüber GFS als Empfangsvertreter
                                    für sämtliche Teilnehmenden KVT als auch einzelne Module (durch Abwahl) während der Nutzungslaufzeit mit einer Frist von zwei Monaten
                                    zum Monatsende abkündigen; eine Kündigung nur gegenüber bestimmten Teilnehmenden KVT ist nicht möglich. Die Kündigungserklärung muss
                                    GFS elektronisch durch das Portal, per E-Mail oder schriftlich zugehen. Umgekehrt kann GFS als Vertreter für sämtliche Teilnehmenden
                                    KVTs die Teilnahme des Teilnehmers am GFS-Portal sowie die Nutzung einzelner Module mit derselben Frist abkündigen. Diese Kündigungserklärung
                                    muss dem Teilnehmenden schriftlich zugehen. Mit Wirksamwerden der Kündigung werden sämtliche den Teilnehmer (bzw. das abgekündigte Modul)
                                    betreffenden Daten im Portal gelöscht. Das Recht beider Parteien zur fristlosen Kündigung aus wichtigem Grund bleibt unberührt; bei einer
                                    durch GFS erklärten solchen Kündigung handelt GFS als Vertreter für sämtliche Teilnehmenden KVT.
                                </li>
                                <li>
                                    Der Teilnehmer ist selbst verantwortlich dafür, die in das Portal hochgeladenen oder vom Portal zum Herunterladen zur Verfügung gestellten
                                    Dokumente in seiner Sphäre zu sichern (Back-ups). Die Dokumente werden quartalsweise im fünften Quartal nach ihrer Einstellung (Einstellung durch
                                    den Teilnehmer oder durch GFS) gelöscht. Der Teilnehmer erhält jeweils zwei Wochen vor dem Löschzeitpunkt eine E-Mail von GFS, in der die Löschung
                                    sämtlicher Dokumente eines Quartals angekündigt wird.
                                </li>
                                <li>
                                    Im Übrigen bleibt der zwischen dem Teilnehmer und dem jeweiligen Teilnehmenden KVT bestehende Arzneiversorgungsvertrag unberührt.
                                </li>
                            </ol>
                        </section>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        name: 'Terms',
    }
</script>
<style scoped>
    /*#region Gliederung und Überschriften*/
    section:not(:last-of-type) {
        margin-bottom: 24px;
    }

    section > header {
        margin-bottom: 8px;
        display: flex;
    }
    /*#endregion*/

    /*#region Listen mit einzelnen Punkten*/
    ol {
        counter-reset: paragraph;
        list-style: none;
        padding-left: 2em !important;
    }

    ol > li {
        position: relative;
        text-align: start;
    }

    ol > li:not(:last-of-type) {
        margin-bottom: 8px;
    }

    ol > li::before {
        content: "(" counter(paragraph) ") ";
        counter-increment: paragraph;
        position: absolute;
        left: -2em;
    }
    /*#endregion*/
</style>


